var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "terms-wrap" }, [
      _c("h2", [_vm._v("개인정보 수집·이용 동의(선택)")]),
      _c("p", [
        _vm._v(
          "(주)유비케어(이하 '회사')는 닥터인포 서비스 운영을 위하여 아래와 같이 개인정보를 수집·이용하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다."
        ),
      ]),
      _c("h3", [_vm._v("개인정보 수집·이용 내역")]),
      _c("h4", [_vm._v("[선택] 개인정보 수집 및 이용 동의")]),
      _c("table", [
        _c("colgroup", [
          _c("col", { staticStyle: { width: "33%" } }),
          _c("col", { staticStyle: { width: "33%" } }),
          _c("col", { staticStyle: { width: "33%" } }),
        ]),
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("항목")]),
            _c("th", [_vm._v("수집 및 이용목적")]),
            _c("th", [_vm._v("보유 및 이용기간")]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [
              _vm._v(
                "전문의 여부(전문의/일반의), 전공 과목, 전공 취득 연도, 면허 취득 연도"
              ),
            ]),
            _c("td", [_vm._v("웹세미나 서비스 제공")]),
            _c("td", [
              _vm._v("회원 탈퇴(의사랑 계정 연결 해제) 후 지체없이 삭제"),
            ]),
          ]),
          _c("tr", [
            _c("td", [
              _vm._v(
                "근무형태(개원의/봉직의), 전문의 여부(전문의/일반의), 전공 과목, 전공 취득 연도, 면허 취득 연도"
              ),
            ]),
            _c("td", [
              _vm._v("진료과 별 맞춤형 서비스 제공"),
              _c("br"),
              _vm._v("(맞춤형 설문 및 이용 통계)"),
            ]),
            _c("td", [
              _vm._v("회원 탈퇴(의사랑 계정 연결 해제) 후 지체없이 삭제"),
            ]),
          ]),
        ]),
      ]),
      _c("ul", { staticClass: "tip" }, [
        _c("li", [
          _c("span", [_vm._v("※ ")]),
          _vm._v(
            "서비스 이용과정에서 기기정보, IP주소, 쿠키, 서비스 이용기록이 자동으로 수집될 수 있습니다."
          ),
        ]),
        _c("li", [
          _c("span", [_vm._v("※ ")]),
          _vm._v(
            "위의 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 닥터인포 서비스 이용에 제한을 받을 수 있습니다."
          ),
        ]),
      ]),
      _c("p", { staticClass: "mgt30" }, [
        _vm._v(
          " 더 자세한 내용에 대해서는 [개인정보처리방침]을 참고하시기 바랍니다. "
        ),
      ]),
      _c(
        "a",
        {
          attrs: {
            href: "https://fu.drinfo.co.kr/privacy",
            target: "_blank",
            title: "개인정보처리방침",
          },
        },
        [_vm._v("(https://fu.drinfo.co.kr/privacy)")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }