<template>
  <div class="drPickWrap">
    <div class="title-bar">
      <h1>닥터인포</h1>
    </div>

    <section class="else-wrap">
      <button
        v-if="step < 3"
        class="btn-back"
        @click="historyBack"
      >
        뒤로
      </button>

      <div class="join-wrap">
        <div class="step-flow">
          <ul>
            <li
              :class="{ 'on': step === 1 }"
              style="cursor: pointer"
              @click="clickStep"
            >
              <span>01.</span>이용약관 동의
            </li>
            <li
              :class="{ 'on': step === 2 }"
              style="cursor: pointer"
            >
              <span>02.</span>간편 비밀번호 입력
            </li>
            <li
              :class="{ 'on': step === 3 }"
              style="cursor: pointer"
            >
              <span>03.</span>의사랑 계정 연결 완료
            </li>
          </ul>
        </div>
        <div
          v-if="step === 1"
          class="join-step step1"
        >
          <h2>원활한 서비스 이용을 위해 약관에 동의해 주세요.</h2>

          <div class="checkbox-wrap big-type">
            <input
              id="agreeAll"
              v-model="terms.termsAll"
              type="checkbox"
              @change="changeTermsAll"
            >
            <label
              for="agreeAll"
              class="text-underline"
            >전체 동의합니다.</label>
          </div>

          <p class="tip-txt">
            ※ 선택 항목에 동의하지 않더라도 서비스 이용이 가능합니다.
          </p>

          <ul class="privacy-list">
            <li>
              <div class="checkbox-wrap">
                <input
                  id="agree1"
                  v-model="terms.termsUse"
                  type="checkbox"
                  class="essential-type"
                  name="agreeType"
                  @change="changeTerms"
                >
                <label for="agree1">이용 약관 동의 <strong>(필수)</strong></label>
              </div>
              <button
                class="btn-privacy-view"
                @click="terms.termsUseView = true"
              >
                보기
              </button>
            </li>
            <li>
              <div class="checkbox-wrap">
                <input
                  id="agree2"
                  v-model="terms.termsPrivacyAgree"
                  type="checkbox"
                  class="essential-type"
                  name="agreeType"
                  @change="changeTerms"
                >
                <label for="agree2">개인정보 수집 및 이용 동의 <strong>(필수)</strong></label>
              </div>
              <button
                class="btn-privacy-view"
                @click="terms.termsPrivacyAgreeView = true"
              >
                보기
              </button>
            </li>
            <li>
              <div class="checkbox-wrap">
                <input
                  id="agree3"
                  v-model="terms.termsPrivacySelect"
                  type="checkbox"
                  name="agreeType"
                  @change="changeTerms"
                >
                <label for="agree3">개인정보 수집 및 이용 동의 <strong class="gray">(선택)</strong></label>
              </div>

              <button
                class="btn-privacy-view"
                @click="terms.termsPrivacySelectView = true"
              >
                보기
              </button>

            </li>
            <li>
              <div class="checkbox-wrap">
                <input
                  id="agree4"
                  v-model="terms.termsThirdAgree"
                  type="checkbox"
                  class="essential-type"
                  name="agreeType"
                  @change="changeTerms"
                >
                <label for="agree4">개인정보 제3자 제공 동의 <strong>(필수)</strong></label>
              </div>

              <button
                class="btn-privacy-view"
                @click="terms.termsThirdAgreeView = true"
              >
                보기
              </button>
            </li>
          </ul>
          <div
            v-if="terms.termsUseView"
            class="layer-terms"
          >
            <div class="privacy-txt privacy1">
              <div class="title">
                <h1>이용약관 동의(필수)</h1>
                <button
                  class="btn-close"
                  @click="terms.termsUseView = false"
                >
                  닫기
                </button>
              </div>
              <terms-use />
            </div>
          </div>

          <div
            v-if="terms.termsPrivacyAgreeView"
            class="layer-terms"
          >
            <div class="privacy-txt">
              <div class="title">
                <h1>개인정보 수집 및 이용 동의(필수)</h1>
                <button
                  class="btn-close"
                  @click="terms.termsPrivacyAgreeView = false"
                >
                  닫기
                </button>
              </div>
              <terms-privacy-agree />
            </div>
          </div>

          <div
            v-if="terms.termsPrivacySelectView"
            class="layer-terms"
          >
            <div class="privacy-txt">
              <div class="title">
                <h1>개인정보 수집·이용 동의(선택)</h1>
                <button
                  class="btn-close"
                  @click="terms.termsPrivacySelectView = false"
                >
                  닫기
                </button>
              </div>
              <terms-privacy-select />
            </div>
          </div>

          <div
            v-if="terms.termsThirdAgreeView"
            class="layer-terms"
          >
            <div class="privacy-txt">
              <div class="title">
                <h1>개인정보 제3자 제공 동의(필수)</h1>
                <button
                  class="btn-close"
                  @click="terms.termsThirdAgreeView = false"
                >
                  닫기
                </button>
              </div>
              <terms-third-agree />
            </div>
          </div>

          <div class="btn-2-wrap">
            <button
              class="btn-next"
              @click="agreeTerms"
            >
              동의
            </button>
          </div>
        </div>

        <div
          v-else-if="step === 2"
          class="join-step step2"
        >
          <div class="join-write">
            <h1>닥터인포</h1>
            <ul v-show="password === null || password.length < 6">
              <li>
                <p class="password-txt">닥터인포에서 사용할<br>
                  간편 비밀번호 6자리 숫자를 입력해 주세요.</p>
              </li>
              <li>
                <label class="hidden">비밀번호</label>
                <div class="text-wrap">
                  <input
                    ref="inputPassword"
                    v-model="password"
                    v-number
                    v-focus
                    type="password"
                    maxlength="6"
                    class="inputPassword"
                  >
                </div>
              </li>
            </ul>
            <ul v-show="password !== null && password.length == 6">
              <li>
                <p class="password-txt">
                  비밀번호 확인을 위해 한번 더 입력해주세요.
                </p>
              </li>
              <li>
                <label class="hidden">비밀번호</label>
                <div class="text-wrap">
                  <input
                    ref="checkPassword"
                    v-model="checkPassword"
                    v-number
                    type="password"
                    maxlength="6"
                    :class="errorMessage ? 'error' : 'inputPassword'"
                    :disabled="isProcessing"
                    @keyup="matchPassword"
                  >
                </div>
              </li>
            </ul>

            <div
              v-if="errorMessage"
              class="message-box"
            >
              <p><i />{{ errorMessage }}</p>
            </div>
          </div>
          <div class="join-txt">
            <p>
              의사랑 로그인 계정: {{ userName }}({{ licenseNum }})
            </p>
            <div class="copyright">
              &copy; UBcare
            </div>
          </div>
        </div>

        <div
          v-else-if="step === 3"
          class="join-step step3"
        >
          <div class="member-completed">
            <p class="welcome-txt">
              닥터인포와 의사랑 계정 연결이 <span>완료</span>되었습니다
            </p>
            <p class="txt-gray">최초 로그인 시 자동로그인이 기본으로 설정되며<br>
              자동로그인을 해제하려면 로그인 후 로그아웃 버튼을 클릭해 주세요.</p>
            <button
              class="btn-w200"
              @click="$router.push({ name: 'login-main' })"
            >
              로그인 하기
            </button>
          </div>
          <div class="join-txt">
            <p>
              의사랑 로그인 계정: {{ userName }} ({{ licenseNum }})
            </p>
            <div class="copyright">
              &copy; UBcare
            </div>
          </div>
        </div>
      </div>

      <alert-dialog :options="alertProps" />
    </section>
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import TermsUse from '@/views/pages/account/terms/TermsUse.vue'
import TermsPrivacyAgree from '@/views/pages/account/terms/TermsPrivacyAgree.vue'
import TermsPrivacySelect from '@/views/pages/account/terms/TermsPrivacySelect.vue'
import TermsThirdAgree from '@/views/pages/account/terms/TermsThirdAgree.vue'

export default {
  components: {
    TermsUse,
    TermsPrivacyAgree,
    TermsPrivacySelect,
    TermsThirdAgree
  },
  data() {
    return {
      step: 1,
      terms: {
        termsAll: false,
        termsUse: false,
        termsPrivacyAgree: false,
        termsPrivacySelect: false,
        termsThirdAgree: false,
        termsUseView: false,
        termsPrivacyAgreeView: false,
        termsPrivacySelectView: false,
        termsThirdAgreeView: false,
      },
      password: null,
      checkPassword: null,
      errorMessage: null,
      isProcessing: false,
    }
  },
  computed: {
    ...mapGetters({
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
      licenseNum: 'infoData/getLicenseNum',
      hAddress: 'infoData/getHAddress',
      medicalDept: 'infoData/getMedicalDept',
      userName: 'infoData/getUserName',
      hName: 'infoData/getHName'
    }),
  },
  watch: {
    async password() {
      if (this.password !== null && this.password.length === 6) {
        await this.$nextTick()

        this.$refs.checkPassword.focus()
      }
    }
  },
  mounted() {
  },
  methods: {
    changeTermsAll() {
      this.terms.termsUse = this.terms.termsAll
      this.terms.termsPrivacyAgree = this.terms.termsAll
      this.terms.termsPrivacySelect = this.terms.termsAll
      this.terms.termsThirdAgree = this.terms.termsAll
    },
    changeTerms() {
      if (this.terms.termsUse && this.terms.termsPrivacyAgree && this.terms.termsPrivacySelect && this.terms.termsThirdAgree) this.terms.termsAll = true
      else this.terms.termsAll = false
    },
    agreeTerms() {
      if (!this.terms.termsUse || !this.terms.termsPrivacyAgree || !this.terms.termsThirdAgree) this.showAlertDialog('필수 항목은 반드시 동의하셔야 합니다.')
      else {
        this.step = 2

        this.errorMessage = null
      }
    },
    matchPassword() {
      if (this.checkPassword === null || this.checkPassword.length < 6) this.errorMessage = null
      else if (this.checkPassword.length === 6 && this.password !== this.checkPassword) this.errorMessage = '비밀번호가 일치하지 않습니다.'
      else if (this.checkPassword.length === 6 && this.password === this.checkPassword) {
        this.errorMessage = null

        if (!this.isProcessing) {
          this.isProcessing = true

          axios.post("/fu/user", {
            userLoginId: this.shaEnLicenseNum,
            userName: this.userName,
            password: this.password,
            hcpLicenseNumber: this.licenseNum,
            hcpMedicalDept: this.medicalDept,
            hcpWorkPlace: this.hName,
            hcpWorkAddress: this.hAddress,
            termsUse: this.terms.termsUse,
            termsPrivacyAgree: this.terms.termsPrivacyAgree,
            termsPrivacySelect: this.terms.termsPrivacySelect,
            termsThirdAgree: this.terms.termsThirdAgree
          })
            .then(() => {
              this.$store.commit('util/setJoin', true)
              this.$store.commit('util/setAccess', true)

              this.step = 3
              this.isProcessing = false
            })
            .catch(() => {
              this.showAlertDialog('의사랑 계정 연결 과정 중 오류가 발생하였습니다.')
            })
        }
      }
    },
    clickStep() {
      if (this.step === 2) {
        this.terms.termsAll = false

        this.changeTermsAll()

        this.password = null
        this.checkPassword = null

        this.step = 1
      }
    },
    historyBack() {
      if (this.step === 1) this.$router.push({ name: 'login-main', params: { back: 'main' } })
      else if (this.step === 2) this.clickStep()
    }
  }
}
</script>
