var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    directives: [
      {
        name: "dompurify-html",
        rawName: "v-dompurify-html",
        value: _vm.termsUseContent,
        expression: "termsUseContent",
      },
    ],
    attrs: { title: "이용 약관 " },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }