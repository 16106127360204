<template>
  <div
    v-dompurify-html="termsUseContent"
    title="이용 약관 "
  />
</template>

<script>
import axios from '@axios'

export default {
  data() {
    return {
      termsUseContent: null,
    }
  },
  created() {
    this.getTotalServiceTerms()
  },
  methods: {
    getTotalServiceTerms() {
      axios.get(`/fu/terms/service`, {
      })
        .then(rs => {
          this.termsUseContent = rs.data.contents
        })
        .catch(error => {
          this.showAlertDialog(error.response.data)
        })
    },
  }
}
</script>
