var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drPickWrap" }, [
    _vm._m(0),
    _c(
      "section",
      { staticClass: "else-wrap" },
      [
        _vm.step < 3
          ? _c(
              "button",
              { staticClass: "btn-back", on: { click: _vm.historyBack } },
              [_vm._v(" 뒤로 ")]
            )
          : _vm._e(),
        _c("div", { staticClass: "join-wrap" }, [
          _c("div", { staticClass: "step-flow" }, [
            _c("ul", [
              _c(
                "li",
                {
                  class: { on: _vm.step === 1 },
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.clickStep },
                },
                [_c("span", [_vm._v("01.")]), _vm._v("이용약관 동의 ")]
              ),
              _c(
                "li",
                {
                  class: { on: _vm.step === 2 },
                  staticStyle: { cursor: "pointer" },
                },
                [_c("span", [_vm._v("02.")]), _vm._v("간편 비밀번호 입력 ")]
              ),
              _c(
                "li",
                {
                  class: { on: _vm.step === 3 },
                  staticStyle: { cursor: "pointer" },
                },
                [_c("span", [_vm._v("03.")]), _vm._v("의사랑 계정 연결 완료 ")]
              ),
            ]),
          ]),
          _vm.step === 1
            ? _c("div", { staticClass: "join-step step1" }, [
                _c("h2", [
                  _vm._v("원활한 서비스 이용을 위해 약관에 동의해 주세요."),
                ]),
                _c("div", { staticClass: "checkbox-wrap big-type" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.terms.termsAll,
                        expression: "terms.termsAll",
                      },
                    ],
                    attrs: { id: "agreeAll", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.terms.termsAll)
                        ? _vm._i(_vm.terms.termsAll, null) > -1
                        : _vm.terms.termsAll,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.terms.termsAll,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.terms,
                                  "termsAll",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.terms,
                                  "termsAll",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.terms, "termsAll", $$c)
                          }
                        },
                        _vm.changeTermsAll,
                      ],
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "text-underline",
                      attrs: { for: "agreeAll" },
                    },
                    [_vm._v("전체 동의합니다.")]
                  ),
                ]),
                _c("p", { staticClass: "tip-txt" }, [
                  _vm._v(
                    " ※ 선택 항목에 동의하지 않더라도 서비스 이용이 가능합니다. "
                  ),
                ]),
                _c("ul", { staticClass: "privacy-list" }, [
                  _c("li", [
                    _c("div", { staticClass: "checkbox-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.terms.termsUse,
                            expression: "terms.termsUse",
                          },
                        ],
                        staticClass: "essential-type",
                        attrs: {
                          id: "agree1",
                          type: "checkbox",
                          name: "agreeType",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.terms.termsUse)
                            ? _vm._i(_vm.terms.termsUse, null) > -1
                            : _vm.terms.termsUse,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.terms.termsUse,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.terms,
                                      "termsUse",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.terms,
                                      "termsUse",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.terms, "termsUse", $$c)
                              }
                            },
                            _vm.changeTerms,
                          ],
                        },
                      }),
                      _vm._m(1),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-privacy-view",
                        on: {
                          click: function ($event) {
                            _vm.terms.termsUseView = true
                          },
                        },
                      },
                      [_vm._v(" 보기 ")]
                    ),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "checkbox-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.terms.termsPrivacyAgree,
                            expression: "terms.termsPrivacyAgree",
                          },
                        ],
                        staticClass: "essential-type",
                        attrs: {
                          id: "agree2",
                          type: "checkbox",
                          name: "agreeType",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.terms.termsPrivacyAgree)
                            ? _vm._i(_vm.terms.termsPrivacyAgree, null) > -1
                            : _vm.terms.termsPrivacyAgree,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.terms.termsPrivacyAgree,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.terms,
                                      "termsPrivacyAgree",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.terms,
                                      "termsPrivacyAgree",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.terms, "termsPrivacyAgree", $$c)
                              }
                            },
                            _vm.changeTerms,
                          ],
                        },
                      }),
                      _vm._m(2),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-privacy-view",
                        on: {
                          click: function ($event) {
                            _vm.terms.termsPrivacyAgreeView = true
                          },
                        },
                      },
                      [_vm._v(" 보기 ")]
                    ),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "checkbox-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.terms.termsPrivacySelect,
                            expression: "terms.termsPrivacySelect",
                          },
                        ],
                        attrs: {
                          id: "agree3",
                          type: "checkbox",
                          name: "agreeType",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.terms.termsPrivacySelect)
                            ? _vm._i(_vm.terms.termsPrivacySelect, null) > -1
                            : _vm.terms.termsPrivacySelect,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.terms.termsPrivacySelect,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.terms,
                                      "termsPrivacySelect",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.terms,
                                      "termsPrivacySelect",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.terms, "termsPrivacySelect", $$c)
                              }
                            },
                            _vm.changeTerms,
                          ],
                        },
                      }),
                      _vm._m(3),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-privacy-view",
                        on: {
                          click: function ($event) {
                            _vm.terms.termsPrivacySelectView = true
                          },
                        },
                      },
                      [_vm._v(" 보기 ")]
                    ),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "checkbox-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.terms.termsThirdAgree,
                            expression: "terms.termsThirdAgree",
                          },
                        ],
                        staticClass: "essential-type",
                        attrs: {
                          id: "agree4",
                          type: "checkbox",
                          name: "agreeType",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.terms.termsThirdAgree)
                            ? _vm._i(_vm.terms.termsThirdAgree, null) > -1
                            : _vm.terms.termsThirdAgree,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.terms.termsThirdAgree,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.terms,
                                      "termsThirdAgree",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.terms,
                                      "termsThirdAgree",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.terms, "termsThirdAgree", $$c)
                              }
                            },
                            _vm.changeTerms,
                          ],
                        },
                      }),
                      _vm._m(4),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-privacy-view",
                        on: {
                          click: function ($event) {
                            _vm.terms.termsThirdAgreeView = true
                          },
                        },
                      },
                      [_vm._v(" 보기 ")]
                    ),
                  ]),
                ]),
                _vm.terms.termsUseView
                  ? _c("div", { staticClass: "layer-terms" }, [
                      _c(
                        "div",
                        { staticClass: "privacy-txt privacy1" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _c("h1", [_vm._v("이용약관 동의(필수)")]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-close",
                                on: {
                                  click: function ($event) {
                                    _vm.terms.termsUseView = false
                                  },
                                },
                              },
                              [_vm._v(" 닫기 ")]
                            ),
                          ]),
                          _c("terms-use"),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.terms.termsPrivacyAgreeView
                  ? _c("div", { staticClass: "layer-terms" }, [
                      _c(
                        "div",
                        { staticClass: "privacy-txt" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _c("h1", [
                              _vm._v("개인정보 수집 및 이용 동의(필수)"),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-close",
                                on: {
                                  click: function ($event) {
                                    _vm.terms.termsPrivacyAgreeView = false
                                  },
                                },
                              },
                              [_vm._v(" 닫기 ")]
                            ),
                          ]),
                          _c("terms-privacy-agree"),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.terms.termsPrivacySelectView
                  ? _c("div", { staticClass: "layer-terms" }, [
                      _c(
                        "div",
                        { staticClass: "privacy-txt" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _c("h1", [_vm._v("개인정보 수집·이용 동의(선택)")]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-close",
                                on: {
                                  click: function ($event) {
                                    _vm.terms.termsPrivacySelectView = false
                                  },
                                },
                              },
                              [_vm._v(" 닫기 ")]
                            ),
                          ]),
                          _c("terms-privacy-select"),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.terms.termsThirdAgreeView
                  ? _c("div", { staticClass: "layer-terms" }, [
                      _c(
                        "div",
                        { staticClass: "privacy-txt" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _c("h1", [
                              _vm._v("개인정보 제3자 제공 동의(필수)"),
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "btn-close",
                                on: {
                                  click: function ($event) {
                                    _vm.terms.termsThirdAgreeView = false
                                  },
                                },
                              },
                              [_vm._v(" 닫기 ")]
                            ),
                          ]),
                          _c("terms-third-agree"),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "btn-2-wrap" }, [
                  _c(
                    "button",
                    { staticClass: "btn-next", on: { click: _vm.agreeTerms } },
                    [_vm._v(" 동의 ")]
                  ),
                ]),
              ])
            : _vm.step === 2
            ? _c("div", { staticClass: "join-step step2" }, [
                _c("div", { staticClass: "join-write" }, [
                  _c("h1", [_vm._v("닥터인포")]),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.password === null || _vm.password.length < 6,
                          expression:
                            "password === null || password.length < 6",
                        },
                      ],
                    },
                    [
                      _vm._m(5),
                      _c("li", [
                        _c("label", { staticClass: "hidden" }, [
                          _vm._v("비밀번호"),
                        ]),
                        _c("div", { staticClass: "text-wrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password",
                              },
                              { name: "number", rawName: "v-number" },
                              { name: "focus", rawName: "v-focus" },
                            ],
                            ref: "inputPassword",
                            staticClass: "inputPassword",
                            attrs: { type: "password", maxlength: "6" },
                            domProps: { value: _vm.password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.password = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.password !== null && _vm.password.length == 6,
                          expression:
                            "password !== null && password.length == 6",
                        },
                      ],
                    },
                    [
                      _vm._m(6),
                      _c("li", [
                        _c("label", { staticClass: "hidden" }, [
                          _vm._v("비밀번호"),
                        ]),
                        _c("div", { staticClass: "text-wrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.checkPassword,
                                expression: "checkPassword",
                              },
                              { name: "number", rawName: "v-number" },
                            ],
                            ref: "checkPassword",
                            class: _vm.errorMessage ? "error" : "inputPassword",
                            attrs: {
                              type: "password",
                              maxlength: "6",
                              disabled: _vm.isProcessing,
                            },
                            domProps: { value: _vm.checkPassword },
                            on: {
                              keyup: _vm.matchPassword,
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.checkPassword = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                  _vm.errorMessage
                    ? _c("div", { staticClass: "message-box" }, [
                        _c("p", [_c("i"), _vm._v(_vm._s(_vm.errorMessage))]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "join-txt" }, [
                  _c("p", [
                    _vm._v(
                      " 의사랑 로그인 계정: " +
                        _vm._s(_vm.userName) +
                        "(" +
                        _vm._s(_vm.licenseNum) +
                        ") "
                    ),
                  ]),
                  _c("div", { staticClass: "copyright" }, [
                    _vm._v(" © UBcare "),
                  ]),
                ]),
              ])
            : _vm.step === 3
            ? _c("div", { staticClass: "join-step step3" }, [
                _c("div", { staticClass: "member-completed" }, [
                  _vm._m(7),
                  _vm._m(8),
                  _c(
                    "button",
                    {
                      staticClass: "btn-w200",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ name: "login-main" })
                        },
                      },
                    },
                    [_vm._v(" 로그인 하기 ")]
                  ),
                ]),
                _c("div", { staticClass: "join-txt" }, [
                  _c("p", [
                    _vm._v(
                      " 의사랑 로그인 계정: " +
                        _vm._s(_vm.userName) +
                        " (" +
                        _vm._s(_vm.licenseNum) +
                        ") "
                    ),
                  ]),
                  _c("div", { staticClass: "copyright" }, [
                    _vm._v(" © UBcare "),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-bar" }, [
      _c("h1", [_vm._v("닥터인포")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "agree1" } }, [
      _vm._v("이용 약관 동의 "),
      _c("strong", [_vm._v("(필수)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "agree2" } }, [
      _vm._v("개인정보 수집 및 이용 동의 "),
      _c("strong", [_vm._v("(필수)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "agree3" } }, [
      _vm._v("개인정보 수집 및 이용 동의 "),
      _c("strong", { staticClass: "gray" }, [_vm._v("(선택)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "agree4" } }, [
      _vm._v("개인정보 제3자 제공 동의 "),
      _c("strong", [_vm._v("(필수)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { staticClass: "password-txt" }, [
        _vm._v("닥터인포에서 사용할"),
        _c("br"),
        _vm._v(" 간편 비밀번호 6자리 숫자를 입력해 주세요."),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { staticClass: "password-txt" }, [
        _vm._v(" 비밀번호 확인을 위해 한번 더 입력해주세요. "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "welcome-txt" }, [
      _vm._v(" 닥터인포와 의사랑 계정 연결이 "),
      _c("span", [_vm._v("완료")]),
      _vm._v("되었습니다 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "txt-gray" }, [
      _vm._v("최초 로그인 시 자동로그인이 기본으로 설정되며"),
      _c("br"),
      _vm._v(
        " 자동로그인을 해제하려면 로그인 후 로그아웃 버튼을 클릭해 주세요."
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }