var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "sub-wrap" }, [
    _c("div", { staticClass: "terms-wrap" }, [
      _c("h2", [_vm._v("개인정보 제3자 제공 동의")]),
      _c("p", [
        _vm._v(
          "(주)유비케어(이하 '회사')는 닥터인포 서비스 운영을 위하여 아래와 같이 개인정보를 제3자에게 제공하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다."
        ),
      ]),
      _c("h3", [_vm._v("개인정보 제3자 제공 내역")]),
      _vm._m(0),
      _vm._m(1),
      _c("p", { staticClass: "mgt30" }, [
        _vm._v(
          " 더 자세한 내용에 대해서는 [개인정보처리방침]을 참고하시기 바랍니다. "
        ),
      ]),
      _c(
        "a",
        {
          attrs: { target: "_blank", title: "개인정보처리방침" },
          on: { click: _vm.openPopup },
        },
        [_vm._v("(https://fu.drinfo.co.kr/privacy)")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", [
      _c("colgroup", [
        _c("col", { staticStyle: { width: "25%" } }),
        _c("col", { staticStyle: { width: "30%" } }),
        _c("col", { staticStyle: { width: "30%" } }),
        _c("col", { staticStyle: { width: "15%" } }),
      ]),
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v("제공받는 자")]),
          _c("th", [_vm._v("제공 목적")]),
          _c("th", [_vm._v("제공 항목")]),
          _c("th", [_vm._v("보유 및 이용기간")]),
        ]),
      ]),
      _c("tbody", [
        _c("tr", [
          _c("td", [
            _vm._v("닥터인포 상품 서비스 계약을 체결한 제휴 제약사"),
            _c("br"),
            _vm._v(" * 리스트 형태로 변경"),
            _c("br"),
            _c("span", { staticClass: "txt-red" }, [
              _vm._v("(제휴 제약사 업데이트 시 수시 반영 예정)"),
            ]),
          ]),
          _c("td", [
            _vm._v(" - 제약사 HCP Portal* 내 회원가입 여부 매칭"),
            _c("br"),
            _vm._v(
              " - 제약사 HCP Portal*과 닥터인포 서비스 간 회원 계정 연동/매칭 "
            ),
          ]),
          _c("td", [
            _vm._v(
              " 이름, 의사 면허 번호, 진료과, 근무처 명, 근무처 주소, 휴대 전화 번호, 이메일주소, CI, 생년월일, 성별, 전문의 여부(전문의/일반의), 전공 과목, 전공 취득 연도, 면허 취득 연도 "
            ),
          ]),
          _c("td", [_vm._v(" 제공 목적 달성 시까지 ")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "tip" }, [
      _c("li", [
        _c("span", [_vm._v("※ ")]),
        _vm._v(
          "제약사 HCP 포털? 제약사에서 제공하는 의사 전용 사이트로 의료인에게 필요한 정보를 제공하는 포털 사이트"
        ),
      ]),
      _c("li", [
        _c("span", [_vm._v("※ ")]),
        _vm._v(
          "위의 개인정보 제공에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 웹세미나 참여 서비스 이용에 제한을 받을 수 있습니다."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }