<template>
  <section class="sub-wrap">
    <div class="terms-wrap">
      <h2>개인정보 제3자 제공 동의</h2>
      <p>(주)유비케어(이하 '회사')는 닥터인포 서비스 운영을 위하여 아래와 같이 개인정보를 제3자에게 제공하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다.</p>

      <h3>개인정보 제3자 제공 내역</h3>
      <table>
        <colgroup>
          <col style="width: 25%">
          <col style="width: 30%">
          <col style="width: 30%">
          <col style="width: 15%">
        </colgroup>
        <thead>
          <tr>
            <th>제공받는 자</th>
            <th>제공 목적</th>
            <th>제공 항목</th>
            <th>보유 및 이용기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>닥터인포 상품 서비스 계약을 체결한 제휴 제약사<br>
              * 리스트 형태로 변경<br>
              <span class="txt-red">(제휴 제약사 업데이트 시 수시 반영 예정)</span>
            </td>
            <td>
              - 제약사 HCP Portal* 내 회원가입 여부 매칭<br>
              - 제약사 HCP Portal*과 닥터인포 서비스 간 회원 계정 연동/매칭
            </td>
            <td>
              이름, 의사 면허 번호, 진료과, 근무처 명, 근무처 주소, 휴대 전화 번호, 이메일주소, CI, 생년월일, 성별, 전문의 여부(전문의/일반의), 전공 과목, 전공 취득 연도, 면허 취득 연도
            </td>
            <td>
              제공 목적 달성 시까지
            </td>
          </tr>
        </tbody>
      </table>

      <ul class="tip">
        <li><span>※ </span>제약사 HCP 포털? 제약사에서 제공하는 의사 전용 사이트로 의료인에게 필요한 정보를 제공하는 포털 사이트</li>
        <li><span>※ </span>위의 개인정보 제공에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 웹세미나 참여 서비스 이용에 제한을 받을 수 있습니다.</li>
      </ul>
      <p class="mgt30">
        더 자세한 내용에 대해서는 [개인정보처리방침]을 참고하시기 바랍니다.
      </p>
      <a
        target="_blank"
        title="개인정보처리방침"
        @click="openPopup"
      >(https://fu.drinfo.co.kr/privacy)</a>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    openPopup() {
      window.open('https://fu.drinfo.co.kr/#/privacy', 'privacy', 'width=1450, height=870, resizable=no, scrollbars=no, status=0, titlebar=0, toolbar=0, left=200, top=100')
    },
  }
}
</script>
