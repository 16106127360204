<template>
  <div class="terms-wrap">
    <h2>개인정보 수집·이용 동의(선택)</h2>
    <p>(주)유비케어(이하 '회사')는 닥터인포 서비스 운영을 위하여 아래와 같이 개인정보를 수집·이용하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다.</p>

    <h3>개인정보 수집·이용 내역</h3>
    <h4>[선택] 개인정보 수집 및 이용 동의</h4>
    <table>
      <colgroup>
        <col style="width: 33%">
        <col style="width: 33%">
        <col style="width: 33%">
      </colgroup>
      <thead>
        <tr>
          <th>항목</th>
          <th>수집 및 이용목적</th>
          <th>보유 및 이용기간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>전문의 여부(전문의/일반의), 전공 과목, 전공 취득 연도, 면허 취득 연도</td>
          <td>웹세미나 서비스 제공</td>
          <td>회원 탈퇴(의사랑 계정 연결 해제) 후 지체없이 삭제</td>
        </tr>
        <tr>
          <td>근무형태(개원의/봉직의), 전문의 여부(전문의/일반의), 전공 과목, 전공 취득 연도, 면허 취득 연도</td>
          <td>진료과 별 맞춤형 서비스 제공<br>(맞춤형 설문 및 이용 통계)</td>
          <td>회원 탈퇴(의사랑 계정 연결 해제) 후 지체없이 삭제</td>
        </tr>
      </tbody>
    </table>

    <ul class="tip">
      <li><span>※ </span>서비스 이용과정에서 기기정보, IP주소, 쿠키, 서비스 이용기록이 자동으로 수집될 수 있습니다.</li>
      <li><span>※ </span>위의 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 닥터인포 서비스 이용에 제한을 받을 수 있습니다.</li>
    </ul>
    <p class="mgt30">
      더 자세한 내용에 대해서는 [개인정보처리방침]을 참고하시기 바랍니다.
    </p>
    <a
      href="https://fu.drinfo.co.kr/privacy"
      target="_blank"
      title="개인정보처리방침"
    >(https://fu.drinfo.co.kr/privacy)</a>
  </div>
</template>

<script>
export default {
  methods: {
    openPopup() {
      window.open('https://fu.drinfo.co.kr/#/privacy', 'privacy', 'width=1450, height=870, resizable=no, scrollbars=no, status=0, titlebar=0, toolbar=0, left=200, top=100')
    },
  }
}
</script>
